<template>
  <div class="drugsList" v-loading="loading">
    <!-- 顶部面包屑 -->
    <div class="top_breadcrumb" v-if="drugsList.length">
      <span @click="$router.push('/drugs?type=' + $route.query.type)">
        <i class="el-icon-arrow-left"></i>{{ drugsList[0].drug_type1 }}
      </span>
      <span>
        <i class="el-icon-arrow-left"></i>{{ drugsList[0].drug_type2 }}
      </span>
    </div>

    <!-- 内容 -->
    <div class="content">
      <ul>
        <li @click="goDetail(item.id)" v-for="item in drugsList" :key="item.id">
          <div class="img" v-if="item.picture">
            <img :src="item.picture" alt="" />
          </div>
          <img v-else src="@image/default_drugs.png" alt="" />
          <div class="desc">
            <h4>
              {{ item.common_name }}
              <span class="factory">({{ item.factory }})</span>
            </h4>
            <p class="word">{{ item.specs }}</p>
            <p class="btn">
              <el-button round>
                {{ item.prescription }}
              </el-button>
              <el-button round>
                {{ item.medicare }}
              </el-button>
              <el-button v-if="item.base" round>基药</el-button>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      drugsList: [], // 药品列表
      query: "",
      loading: true,
    };
  },

  async created() {
    await this.categoryDetail();
    let { query } = this;
    query = this.$route.query;
    // 分类信息详情为空时
    if (!this.drugsList.length) {
      this.$router.push(
        "/drugsDetail?type=" + query.type + "&id=" + query.id + "&num=none"
      );
    }
  },

  methods: {
    // 获取分类信息详情
    async categoryDetail() {
      let token = localStorage.getItem("token");
      let name = localStorage.getItem("common_name");
      let drugConfigList = JSON.parse(localStorage.getItem("drugConfigList"));
      let drug_type1 = this.$route.query.type1;
      let drug_type2 = this.$route.query.type2;
      let drug_type3 = this.$route.query.type3;
      let res;
      if (drugConfigList.length) {
        res = await this.$findApi.categoryDetail({
          token,
          drugConfigList,
          name,
        });
      } else {
        res = await this.$findApi.categoryAllDetail({
          name,
          drug_type1,
          drug_type2,
          drug_type3,
        });
      }
      this.loading = false;
      if (res.code == 200) {
        this.drugsList = res.data;
      } else {
        this.$message.error(res.message);
      }
    },

    // 进入详情页
    goDetail(id) {
      this.$router.push(
        "/drugsDetail?type=" + this.$route.query.type + "&id=" + id
      );
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.drugsList {
  background: #f2f2f2;

  // 顶部面包屑
  .top_breadcrumb {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;

    span {
      margin-right: 10px;
      color: #717171;
      cursor: pointer;

      &:last-child {
        color: $--themeColor;
      }

      i {
        margin-right: 5px;
        font-weight: 700;
        color: #717171;
      }
    }
  }

  // 内容
  .content {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid #d9d9d9;
    min-height: 400px;
    margin-bottom: 80px;

    ul {
      li {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid #d9d9d9;
        align-items: center;
        cursor: pointer;

        .img {
          width: 120px;
          height: 73px;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          position: relative;

          img {
            width: 95px;
            height: 56px;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          }
        }

        img {
          width: 120px;
          height: 73px;
          border-radius: 10px;
          object-fit: cover;
        }

        .desc {
          flex: 1;
          margin-left: 20px;

          h4 {
            font-size: 18px;

            .factory {
              color: #8e8e8e;
              font-weight: normal;
              font-size: 16px;
            }
          }

          .word {
            font-size: 14px;
            color: #8e8e8e;
            margin: 8px 0;
          }

          .btn {
            button {
              height: 22px;
              padding: 0 15px;
              font-size: 14px;
              border-color: $--themeColor;
              color: $--themeColor;
            }
          }
        }
      }
    }
  }
}
</style>